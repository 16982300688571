<template>
    <div class="sections-body row ml-2 ml-md-0 pr-5">
        <div v-for="section in sections" class="box-container">
            <a @click="goTo(section.slug_url)" class="box mx-auto">
                <img class="icon" :src="section.imagen.public_path" :alt="section.imagen.alt"/>
                <span class="text" v-html="section.title"></span>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        sections: Array,
        showScroll: {type: Boolean, default: true}
    },
    methods: {
        goTo(url) {
            this.$emit('saveUserSearch', url)
            window.location.href = url
        }
    }
}
</script>

<style lang="scss" scoped>
.sections-body {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
}

.box {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding-inline: 2rem;
    width: 165px;
    height: 165px;
    margin-right: 1rem;
    margin-bottom: 1rem;
    border-radius: 20px;
    border: 1px solid #888888;
    background-color: white;
    transition: all 0.3s ease-in-out;
    text-decoration: none;
}

.box:hover {
    background-color: #f90;
}

.icon {
    width: 30px !important;
    height: auto !important;
    font-size: 50px;
    color: #888888;
}

.text {
    color: #888888;
    font-size: 1.1em;
    text-align: center;
}

.box:hover .icon,
.box:hover .text {
    color: #fff;
}

@media (max-width: 570px) {
    .box {
        width: 115px;
        height: 115px;
    }

    .text {
        font-size: .8em;
    }
}
</style>